import React from "react";
import ContentfulPageWrapper from "storefront/components/Modules/ContentfulPageWrapper";
import usePublicConfig from "storefront/hooks/usePublicConfig";

const Contentful = () => {
  const { contentful } = usePublicConfig();

  const entryIds = contentful.spaces.grailed?.entryIds;

  const contentfulId = entryIds?.homepage;

  if (!contentfulId) return null;

  return (
    <div className="Homepage--Merchandising">
      <ContentfulPageWrapper
        baseClassName="Homepage"
        // @ts-expect-error ts-migrate
        id={contentfulId}
        from="homepage"
        pageType="homepage"
      />
    </div>
  );
};

export default Contentful;
