import React from "react";
import { createRoot } from "react-dom/client";
import { bindActionCreators, Action } from "redux";
import { Provider } from "react-redux";
import { Provider as AuthenticationProvider } from "storefront/hooks/useAuthentication";
import { Provider as AnalyticsProvider } from "storefront/hooks/useAnalytics";
import type { ComponentType } from "react";
import type { Store } from "redux";
import * as fittingRoomActions from "storefront/actions/fittingRoomActions";
import * as filteringActions from "storefront/actions/filteringActions";
import * as sessionActions from "../actions/session_actions";

export const bindActions = (store: Record<string, any>) =>
  bindActionCreators(
    { ...sessionActions, ...filteringActions, ...fittingRoomActions },
    store.dispatch,
  );

const createApp = (
  rootElement: HTMLElement,
  Yield: ComponentType<any>,
  store: Store<Record<string, any>, Action>,
  props?: Record<string, any>,
) => {
  const actions = bindActions(store);
  const root = createRoot(rootElement);
  root.render(
    <Provider store={store}>
      <AuthenticationProvider>
        <AnalyticsProvider>
          <Yield actions={actions} {...props} />
        </AnalyticsProvider>
      </AuthenticationProvider>
    </Provider>,
  );
};

export default createApp;
